declare var resourceUrl: string;
declare var serverUrl: string;

export const environment = {
  production: true,
  localhost: 'localhost:4200',
  mapApiKey: 'AIzaSyAlIhOiTy90nSOl4KtIa2MJl3cM7Xhu_70',
  mapCityRadius: 50,
  // resourceUrl: 'https://uat.api.globalwarranty.net/',
  socketUrl: 'https://api.globalwarranty.net/',
  resourceUrl: 'https://api.globalwarranty.net/',
  serverUrl: 'https://e2.api.globalwarranty.net/',
  messages: {
    apiError: 'An error has occured.  Please contact your administrator.'
  },
  users: [
    { name: 'Lyle Chamarette (Administrator)', email: 'lchamarette@stepsoftware.com', password: 'qESL7Cue4RzMNPt' },
    { name: 'Jeff Johnson (Administrator)', email: 'jeff@tmlinc.ca', password: 'tmlinc2000' },
    { name: 'Adam Olcsvary (Dealer Account Manager)', email: 'adamolcsvary@globalwarranty.com', password: 'gwc-agent1' },
    { name: 'George Pearn (Regional Sales Manager)', email: 'gpearn@globalwarranty.com', password: 'gwc-regional1' }
  ],
  claimsListHighlightRedDaysRangeStart: 3,
  claimsListHighlightRedDaysRangeEnd: 5,
  claimsListHighlightYellowDaysRangeStart: 2,
  claimsListHighlightYellowDaysRangeEnd: 3,
  appVersion: require('../../version.json').version,
  shopLink: 'https://www.shopkey5.com',
  shopLinkLabel: 'Shop Key',
  insurerEmail: "@archinsurance.com",
  insurerDateCutoff: new Date(2022, 3, 1)
};
